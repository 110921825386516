<template>
    <Layout>
        <PageHeader :title="title" :items="items" />
        <div class="row">
            <div class="card">
                <div class="card-body">
                    <div class="row justify-content-between">
                        <div class="col-md-5">
                            <div class="form-group mt-3 mb-0">
                                <div class="search-box me-2">
                                    <div class="position-relative">
                                        <input type="text" class="form-control bg-soft bg-info border-0"
                                            placeholder="Rechercher..." @input="searchFilter($event)" />
                                        <i class="bx bx-search-alt search-icon"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row col-md-6">
                            <div class="form-group col-md-4 mt-3 mb-0">
                                <input type="date" v-model="picked" class="form-control" placeholder="Date de début">
                            </div>
                            <div class="mt-3 col-md-4 mb-0">
                                <button class="btn btn-info">
                                    <i class="bx bx-slider align-middle me-1"></i>
                                    Lancer le filtre
                                </button>
                            </div>
                        </div>
                    </div>
                  <div class="table-responsive mt-3" v-if="!loading">
                        <table class="table table-nowrap table-centered mb-0 align-middle">
                            <thead class="table-light">
                                <tr>
                                    <th scope="col">Titre</th>
                                    <th scope="col">Type incident</th>
                                    <th scope="col">Date</th>
                                    <th scope="col">Lieu</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="incident in incidentsData" :key=incident.id>
                                    <td>{{ incident.Titre }}</td>
                                    <td>{{ incident.type.name }}</td>
                                    <td>{{ incident.date }}</td>
                                    <td>{{ incident.lieu }}</td>
                                    <td>
                                        <button @click="detailsIncidents(incident)" class="btn btn-warning btn-sm me-2 w-xs">Details</button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                  <div v-else class="loading-container">
                    <div class="text-center">
                      <i class="bx bx-loader bx-spin" style="font-size: 5em;"></i>
                      <h1>Chargement</h1>
                    </div>
                  </div>
                </div>
            </div>
        </div>
    </Layout>
</template>

<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import { reqMethods } from '../../../state/helpers';
/**
 * Lending component
 */

export default {
    components: {
        Layout,
        PageHeader
    },
    data() {
        return {
            picked: "",
            daterange: "",
          loading: true,
            title: "Historique des incidents",
            items: [
                {
                    text: "Dashboard",
                    href: "/"
                },
                {
                    text: "Incidents",
                    active: true
                }
            ],
            incidents: [],
            incidentsData: [],
            reqError: null,
        };
    },
    mounted() {
        this.getAllIncidents();
    },
    methods: {
        ...reqMethods,
      getAllIncidents() {
        const that = this;
        this.loading = true;
        this.getRequest('/investigation/incident')
            .then(
                function (response) {
                  that.incidents = response.data;
                  that.incidentsData = response.data;
                  console.log(that.incidents);
                },
                function (error) {
                  that.reqError = error;
                }
            )
            .finally(() => {
              that.loading = false;
            });
      },

        detailsIncidents(incident){
            this.$router.push({ path: `/detail-incident/${incident.id}` });
        },  
        searchFilter(e) {
            const searchStr = e.target.value;
            this.incidentsData = this.incidents.filter((incident) => {
                return (
                    incident.Titre.toLowerCase().includes(searchStr.toLowerCase()) ||
                    incident.type?.name?.toLowerCase().includes(searchStr.toLowerCase())
                );
            });
        },

    }
};
</script>
<style>
.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh
}
</style>